const burger = require('./burger.js');
const flashMessage = require('./flashMessage.js');
const cookieConsent = require("./cookie_consent");

function initScripts()
{
  burger.init();
  flashMessage.init();
  cookieConsent.init();
}

document.addEventListener('DOMContentLoaded', initScripts);
